// extracted by mini-css-extract-plugin
export var itemgroup2 = "style-module--itemgroup2--36869";
export var itemgroup3 = "style-module--itemgroup3--4f176";
export var itemgroup4 = "style-module--itemgroup4--8880c";
export var item = "style-module--item--56664";
export var itemsublisttitle = "style-module--itemsublisttitle--be2f8";
export var itemsublistcollectiontitle = "style-module--itemsublistcollectiontitle--7b0c1";
export var itemcoldefault = "style-module--itemcoldefault--fe1f1";
export var itemcolcollection = "style-module--itemcolcollection--8c5ea";
export var itemgrouptitle = "style-module--itemgrouptitle--ef8d5";
export var itemtitle = "style-module--itemtitle--80536";
export var itemcolor = "style-module--itemcolor--25c2b";
export var popuparea = "style-module--popuparea--441c6";
export var popupbg = "style-module--popupbg--332d4";
export var popupcoloritem = "style-module--popupcoloritem--07c9a";
export var popupcoloritemclose = "style-module--popupcoloritemclose--669fc";
export var popupimagebutton = "style-module--popupimagebutton--42cbc";
export var popupcoloritemchange = "style-module--popupcoloritemchange--692f2";
export var popupcoloritemswatch = "style-module--popupcoloritemswatch--98e51";
export var popupsection = "style-module--popupsection--87e9e";
export var popupdisclaimer = "style-module--popupdisclaimer--83a64";
export var popupcoloritemcolor = "style-module--popupcoloritemcolor--aa195";
export var itemtitlename = "style-module--itemtitlename--9451b";
export var itemtitlecode = "style-module--itemtitlecode--b7bf0";
export var disclaimerarea = "style-module--disclaimerarea--64a8c";
export var colortrend2024subtitle = "style-module--colortrend2024subtitle--75b44";