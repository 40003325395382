import React, { useState } from "react"

import WebappImage from "../image/webappimage"

import requestJSON from "../../../lib/requestJSON"

import imgclose from "../../images/menu-close.png"
import imgleft from "../../images/paintingtools/arrow-left.png"
import imgright from "../../images/paintingtools/arrow-right.png"

import * as styles from "./style.module.css"


const COLORNAMECONSTANTS = {
	white: {
		code: "",
		name: "White",
		headerimageurl: "",
		variant: "",
		file: "",
		imageurl: "",
		id: "1",
		red: 255,
		green: 255,
		blue: 255,
		interior: "0",
		available: "1",
	},
	black: {
		code: "",
		name: "Black",
		headerimageurl: "",
		variant: "",
		file: "",
		imageurl: "",
		id: "1",
		red: 0,
		green: 0,
		blue: 0,
		interior: "0",
		available: "1",
	}
}

const Colorlist = ({ listobj, columntype, listid }) => {
	const [coloridx, setColoridx] = useState(-1);
	const [productlist, setProductlist] = useState([]);


	const getColorId = function(colorobj)
	{
		var tmpval = "0";
		if (colorobj.hasOwnProperty("jsonId")) {
			if (colorobj.jsonId) {
				tmpval = ""+colorobj.jsonId;
			}
		} else if (colorobj.hasOwnProperty("id")) {
			if (colorobj.id) {
				tmpval = ""+colorobj.id;
			}
		}
		return parseInt(tmpval, 10)
	}

	const closeColorDetails = function(e)
	{
		if (e) {
			e.preventDefault();
		}
		setColoridx(-1);
		setProductlist([]);
	}

	const getNextItem = function(e)
	{
		if (e) {
			e.preventDefault();
		}
		const startidx = coloridx;
		var curidx = coloridx;
		while (true) {
			if (curidx === listobj.length-1) {
				curidx = 0;
			} else {
				curidx++;
			}
			if (curidx === startidx) {
				return;
			}
			if (getColorId(listobj[curidx]) > 0) {
				showColorDetails(null, curidx);
				break;
			}
		}
	}


	const getPrevItem = function(e)
	{
		if (e) {
			e.preventDefault();
		}
		const startidx = coloridx;
		var curidx = coloridx;
		while (true) {
			if (curidx === 0) {
				curidx = listobj.length-1;
			} else {
				curidx--;
			}
			if (curidx === startidx) {
				return;
			}
			if (getColorId(listobj[curidx]) > 0) {
				showColorDetails(null, curidx);
				break;
			}
		}
	}

	const showColorDetails = function(e, newcoloridx)
	{
		if (e) {
			e.preventDefault();
		}
		setColoridx(newcoloridx);
		const colorid = getColorId(listobj[newcoloridx]);
		const submitURL = "../data/colorlist/list"+(Math.floor(colorid/100)+1)+".json";

		requestJSON.send(submitURL, "GET", {}).then(response => {
			if (response) {
				var tmpidx = 0;
				var pkid = 0;
				while (tmpidx < response.length) {
					pkid = parseInt(response[tmpidx].id, 10);
					if (pkid === colorid) {
						setProductlist(response[tmpidx].product);
						break;
					}
					tmpidx++;
				}
			}
		});
	}

	const generateColorItemSwatch = function(coloriteminfo, title)
	{
		var red = parseInt(coloriteminfo.red, 10);
		var green = parseInt(coloriteminfo.green, 10);
		var blue = parseInt(coloriteminfo.blue, 10);

		const WHITELIMIT = 240;
		const iswhitecolor = (red>=WHITELIMIT && green>=WHITELIMIT && blue>=WHITELIMIT)
		return <div className={styles.itemcolor}>
			{red>=0 ?<>
				<div style={{
					position:"relative",
					width:"100%",
					display:"block",
					paddingBottom:"100%",
					border:(iswhitecolor) ? "1px solid #dddddd": "1px solid #ffffff",
					backgroundColor:"rgb("+red+","+green+","+blue+")"}}
				/>
			</>:
				<WebappImage httowdpercent={"100%"} srcurl={coloriteminfo.imageurl} origwd={2048} alt={title} />
			}
		</div>
	}

	const generateColorName = function(coloriteminfo, boldmode = false)
	{
		var colorname = coloriteminfo.name;
		var tmpidx = colorname.indexOf(" with ");
		if (tmpidx > 0) {
			var tmpcolorname = colorname.substring(0, tmpidx)+"<br/><br/>w/ ";
			if (boldmode) {
				tmpcolorname = tmpcolorname + "<span class=\"font-style-bold\">";
			}
			colorname = colorname.substring(tmpidx+6);
			tmpidx = colorname.indexOf(" ");
			tmpcolorname = tmpcolorname+colorname.substring(0, tmpidx);

			if (boldmode) {
				tmpcolorname = tmpcolorname + "</span>";
			}
			tmpcolorname = tmpcolorname+"<br/>"+colorname.substring(tmpidx+1);

			colorname = tmpcolorname;
		}
		return colorname.replace(/\(Semi-Gloss\)/g,"");
	}

	const generateColorItem = function(coloriteminfo, title)
	{
		var colorname = generateColorName(coloriteminfo, true);

		return <>
			{generateColorItemSwatch(coloriteminfo, title)}
			<div className={styles.itemtitle+" font-size-small"}>
				{coloriteminfo.code.length > 0 && <div className={styles.itemtitlecode+" font-style-bold"} dangerouslySetInnerHTML={{__html:coloriteminfo.code}} />}
				{coloriteminfo.name.length > 0 && <div className={styles.itemtitlename} dangerouslySetInnerHTML={{__html:colorname}} />}
			</div>
		</>
	}

	const generateColorItemGroup = function (startidx, title, columnstyle)
	{
		var colorname = generateColorName(listobj[startidx], true);
		var tmpidx = startidx;
		var colorlist = [];
		var tmpobj = {};
		while (tmpidx < listobj.length) {
			if (listobj[tmpidx].code !== listobj[startidx].code) {
				break;
			}
			tmpobj = JSON.parse(JSON.stringify(listobj[tmpidx]));
			tmpobj.name = tmpobj.variant;
			tmpobj.code = "";
			tmpobj.variant = "";
			colorlist.push(tmpobj);
			tmpidx++
		}
		return <div className={(colorlist.length === 2 ? styles.itemgroup2: (colorlist.length === 3 ? styles.itemgroup3: styles.itemgroup4))}>
			<div className={styles.itemgrouptitle+" font-size-small"}>
				{listobj[startidx].code.length > 0 && <div className={styles.itemtitlecode+" font-style-bold"} dangerouslySetInnerHTML={{__html:listobj[startidx].code}} />}
				{listobj[startidx].name.length > 0 && <div className={styles.itemtitlename} dangerouslySetInnerHTML={{__html:colorname}} />}
			</div>
			{colorlist.map((coloritem, curcoloridx)=> {
				return <div onClick={(e)=>{showColorDetails(e, startidx+curcoloridx)}} className={styles.item}>
					{generateColorItem(coloritem, title)}
				</div>
			})}
		</div>

	}

	const generateColorPopupItem = function(loadidx)
	{
		var coloriteminfo = listobj[loadidx];
		var jsonid = getColorId(coloriteminfo);

		if (jsonid<1) {
			var tmptitle = coloriteminfo.name.toLowerCase();
			if (COLORNAMECONSTANTS.hasOwnProperty(tmptitle)) {
				coloriteminfo = COLORNAMECONSTANTS[tmptitle];
			}
		}
		var colorname = generateColorName(coloriteminfo);

		return <div className={styles.popupcoloritem}>
			<div className={styles.popupcoloritemclose} onClick={closeColorDetails}><button className={styles.popupimagebutton}><img src={imgclose} alt="x"  /></button></div>
			<div className={styles.popupcoloritemcolor}>
				<button onClick={getPrevItem} className={styles.popupimagebutton +" "+styles.popupcoloritemchange}>
					<img src={imgleft} alt="Prev"/>
				</button>
				<div className={styles.popupcoloritemswatch}>
					{generateColorItemSwatch(coloriteminfo, "Color")}
				</div>
				<button onClick={getNextItem} className={styles.popupimagebutton +" "+styles.popupcoloritemchange}>
					<img src={imgright} alt="Next"/>
				</button>
			</div>
			<div className={styles.itemtitle+" text-centered"}>
				{coloriteminfo.name.length > 0 && <div className={styles.popupsection}>
					<div className="textcolor-secondary font-size-small">Color Name</div>
					<div className={styles.itemtitlename+" font-style-bold"} dangerouslySetInnerHTML={{__html:colorname}} />
					{coloriteminfo.variant.length > 0 && <div>({coloriteminfo.variant})</div>}
				</div>}
				{coloriteminfo.code.length > 0 && <div className={styles.popupsection}>
					<div className="textcolor-secondary font-size-small">Color Code</div>
					<div className={styles.itemtitlecode+" font-style-bold"} dangerouslySetInnerHTML={{__html:coloriteminfo.code}} />
				</div>}
				{productlist.length > 0 && <div className={styles.popupsection}>
					<div className="textcolor-secondary font-size-small">Product / Collection</div>
					{productlist.map((productobj, productobjidx)=> {
						return <div className={"font-style-bold"} dangerouslySetInnerHTML={{__html:(productobj.name+" "+productobj.subtitle)}} />

					})}
				</div>}
			</div>
			<div className={"font-size-small text-centered "+styles.popupdisclaimer}>
				*Colors on screen may not exactly match actual paint color. Additionally, some colors are for interiors only. Please check color details and specify use with merchandiser before ordering. For product specifics, see Choose Your Paint.
			</div>
		</div>
	}


	const customlistid = typeof listid === "undefined"?"":listid;
	var columnstyle = styles.itemcoldefault;
	var titlestyle = styles.itemsublisttitle;

	if (typeof columntype !== "undefined") {
		if (columntype === "collection") {
			titlestyle = "text-centered font-style-bold "+styles.itemsublistcollectiontitle+" "+styles.itemsublisttitle;
			columnstyle = styles.itemcolcollection;
		}
	}

	return (<>
		{listobj.map((coloritem, curcoloridx)=> {
			var jsonid = getColorId(coloritem);
			var title = "";
			if (coloritem.code.length > 0) {
				title = coloritem.code;
				if (coloritem.name.length > 0) {
					title = title + " " + coloritem.name;
				}
				// h3
			} else {
				title = coloritem.name;
			}
			if (COLORNAMECONSTANTS.hasOwnProperty(title.toLowerCase()) && jsonid < 1) {
				coloritem = COLORNAMECONSTANTS[title.toLowerCase()];
				jsonid = getColorId(coloritem);
			}

			if (jsonid < 1) {
				var titlemain = false;
				if (coloritem.code.length > 0) {
					titlemain = true;
				}
				if (customlistid === "colorcollection/boysen-color-trend-2024") {
					return <div className={styles.colortrend2024subtitle}>
						<img src={"https://files.boysencdn.com/boysen/uploads/custom/colorcollection/colortrend2024/"+title.toLowerCase()+".png"}  alt={title} />
					</div>
				} else {
					return <div className={titlestyle} dangerouslySetInnerHTML={{__html:title}} />
				}
			}
			if (curcoloridx>0) {
				if (coloritem.code === listobj[curcoloridx-1].code && coloritem.code.length > 0) {
					return <></>
				}
			}
			if (curcoloridx+1 < listobj.length) {
				if (coloritem.code === listobj[curcoloridx+1].code && coloritem.code.length > 0) {
					return generateColorItemGroup(curcoloridx, title, columnstyle)
				}
			}

			return <div onClick={(e)=>{showColorDetails(e, curcoloridx)}} className={styles.item +" "+columnstyle}>
				{generateColorItem(coloritem, title)}
			</div>
		})}
		{listobj.length>0 && <div className={styles.disclaimerarea+" text-centered"}>
			<div>* For technical reasons connected with color reproduction in print and paint manufacturing,</div>these colors may not exactly match actual paint color.
		</div>}
		{coloridx>=0 && <div className={styles.popuparea}>
			<div className={styles.popupbg} onClick={closeColorDetails}></div>
			{generateColorPopupItem(coloridx)}
		</div>}
	</>)
}

export default Colorlist
